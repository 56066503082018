import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.headLineText) + " "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.yearStartSelection) + "-" + _vm._s(this.yearEndSelection))])])]), _vm._m(0), _c(VExpansionPanels, {
    staticClass: "py-4 mx-auto",
    model: {
      value: _vm.filterPanel,
      callback: function ($$v) {
        _vm.filterPanel = $$v;
      },
      expression: "filterPanel"
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "py-0",
    attrs: {
      "expand-icon": "mdi-menu-down"
    }
  }, [_c(VContainer, [_c(VLayout, {
    staticClass: "d-flex align-center",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('span', {
    staticClass: "mr-2 pt-1"
  }, [_c(VIcon, [_vm._v("mdi-filter-outline")]), _vm._v("Filter")], 1)])], 1)], 1), _c(VExpansionPanelContent, [_c('div', {
    directives: [{
      name: "-if",
      rawName: "v--if",
      value: !_vm.isCollapsed,
      expression: "!isCollapsed"
    }],
    staticClass: "filter-content"
  }, [_c('div', {
    attrs: {
      "id": "yearContainer"
    }
  }, [_c('div', {
    attrs: {
      "id": "StartYear"
    }
  }, [_c(VSelect, {
    ref: "yearChart",
    staticClass: "me-3 fit",
    attrs: {
      "hint": "Anfangsjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.yearStartSelection,
      callback: function ($$v) {
        _vm.yearStartSelection = $$v;
      },
      expression: "yearStartSelection"
    }
  })], 1), _c('div', {
    attrs: {
      "id": "EndYear"
    }
  }, [_c(VSelect, {
    ref: "yearChart",
    staticClass: "mx-3 fit",
    attrs: {
      "hint": "Endjahr",
      "persistent-hint": "",
      "items": _vm.generateYearsOptions()
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.yearEndSelection,
      callback: function ($$v) {
        _vm.yearEndSelection = $$v;
      },
      expression: "yearEndSelection"
    }
  })], 1)]), _c('div', {
    attrs: {
      "id": "addressType"
    }
  }, [_c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": _vm.addressTypesItems,
      "item-text": "name",
      "item-value": "id",
      "label": "Adressart",
      "chips": "",
      "clearable": "",
      "deletable-chips": "",
      "multiple": ""
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.selectedAddressType,
      callback: function ($$v) {
        _vm.selectedAddressType = $$v;
      },
      expression: "selectedAddressType"
    }
  }, [_c(VChip, {
    attrs: {
      "close": ""
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "id": "CustomerGroup"
    }
  }, [_c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": _vm.customerGroupsArray,
      "item-text": "name",
      "item-value": "id",
      "label": "Kundengruppe",
      "multiple": "",
      "chips": "",
      "clearable": "",
      "deletable-chips": ""
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.selectedGroup,
      callback: function ($$v) {
        _vm.selectedGroup = $$v;
      },
      expression: "selectedGroup"
    }
  })], 1), _c('div', {
    attrs: {
      "id": "Branches"
    }
  }, [_c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": _vm.shops,
      "item-text": "name",
      "item-value": "id",
      "label": "Filialen",
      "multiple": "",
      "chips": "",
      "clearable": "",
      "deletable-chips": ""
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.selectBranche,
      callback: function ($$v) {
        _vm.selectBranche = $$v;
      },
      expression: "selectBranche"
    }
  })], 1)])])], 1)], 1), _c('br'), _c('div', {
    attrs: {
      "id": "graph12"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-top": "-5px"
    },
    attrs: {
      "id": "graph1"
    }
  }, [_c('h3', [_vm._v("Kunden im Jahresvergleich")]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack1
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward1
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    attrs: {
      "id": "chart1"
    }
  }, [_c('apexchart', {
    ref: "totalChart",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.totalChartOptions,
      "series": _vm.total
    }
  })], 1)]), _c('div', {
    attrs: {
      "id": "graph2"
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack2
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward2
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    attrs: {
      "id": "chart2"
    }
  }, [_c('apexchart', {
    ref: "totalChart2",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.customerDbRevenueOptions,
      "series": _vm.customerDbRevenueSeries
    }
  })], 1)])]), _c('h3', [_vm._v("Kundenanzahl in Dokument")]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack1921
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward1921
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    attrs: {
      "id": "chart1921"
    }
  }, [_c('apexchart', {
    ref: "totalChart1921",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.customerDocumentOptions,
      "series": _vm.customerDocumentSeries
    }
  })], 1), _c('div', {
    attrs: {
      "id": "graph3"
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('h3', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" 10 stärkste Lieferanten "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.yearStartSelection) + "-" + _vm._s(this.yearEndSelection))])]), _c('div', {
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c(VSwitch, {
    attrs: {
      "label": "Kunden invertieren"
    },
    on: {
      "change": _vm.changeswitch
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "id": "v-btn",
      "icon": "",
      "disabled": _vm.checkifYEARisset(),
      "color": "black"
    },
    on: {
      "click": function ($event) {
        _vm.strongestCustomersYear -= 1;
        _vm.strongestCustomers();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "id": "v-btn",
      "icon": "",
      "disabled": _vm.checkifYEARisset(),
      "color": "black"
    },
    on: {
      "click": function ($event) {
        _vm.strongestCustomersYear += 1;
        _vm.strongestCustomers();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    attrs: {
      "id": "chart3"
    }
  }, [_c('apexchart', {
    ref: "totalChart3",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.strongestCustomersOptions,
      "series": _vm.strongestCustomersSeries
    }
  })], 1)]), _c('div', {
    attrs: {
      "id": "graph4"
    }
  }, [_c('h3', [_vm._v("Artikelbewegungen")]), _c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": this.AllCustomersList,
      "item-text": "name",
      "item-value": "lfdnr",
      "label": "KundenDetails"
    },
    on: {
      "change": _vm.test
    },
    model: {
      value: _vm.selectedCustomer,
      callback: function ($$v) {
        _vm.selectedCustomer = $$v;
      },
      expression: "selectedCustomer"
    }
  }), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": function ($event) {
        _vm.CustomerDetailsYear -= 1;
        _vm.CustomerDetails();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": function ($event) {
        _vm.CustomerDetailsYear += 1;
        _vm.CustomerDetails();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('div', {
    staticClass: "tab"
  }, [_c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'artikeltypen');
      }
    }
  }, [_vm._v(" Artikeltypen ")]), _c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'artikelkategorie');
      }
    }
  }, [_vm._v(" Artikelkategorie ")]), _c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'artikelgruppe');
      }
    }
  }, [_vm._v(" Artikelgruppe ")]), _c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'statistikgruppen');
      }
    }
  }, [_vm._v(" Statistikgruppe ")]), _c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'erloesgruppen');
      }
    }
  }, [_vm._v(" Erlösgruppe ")]), _c('button', {
    staticClass: "tablinks",
    on: {
      "click": function ($event) {
        return _vm.TabSelect($event, 'statistikcode');
      }
    }
  }, [_vm._v(" Statistikcode ")])]), _c('div', {
    attrs: {
      "id": this.selectedTab
    }
  }, [_c('apexchart', {
    ref: "totalChart4",
    attrs: {
      "type": "bar",
      "height": "500",
      "options": _vm.customerDetailsOptions,
      "series": _vm.customerDetailsSeries
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "id": "CustomerDocuments"
    }
  }, [_c(VTextField, {
    staticClass: "my-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.header2,
      "items": _vm.CustomerDocumentsItems,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "kdnr"
    },
    on: {
      "click:row": _vm.handleRowClick
    }
  })], 1), _c('div', {
    attrs: {
      "id": "CustomerDocuments"
    }
  }, [_c(VTextField, {
    staticClass: "my-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.header,
      "items": _vm.CustomerDocumentsItems2,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "kdnr"
    }
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('h3', [_c('span', {
    staticClass: "subtitle-2"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h3', [_vm._v("Deckungsbetrag / Umsatz pro Kunde")])]);
}];
export { render, staticRenderFns };