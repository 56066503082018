import axios from 'axios'

export default {
  getOffersForYearSpan(urlParam, yearStart, yearEnd, inlcudeMonth) {
    return axios.post('/v1/statistics/offer/years' + urlParam, {
      from: yearStart,
      to: yearEnd,
      includeCurrentMonth: inlcudeMonth
    })
  },

  getMonthlyRevenueOfYear(urlParam, yearStart, yearEnd) {
    return axios.post('/v1/statistics/offer/monthsOfYear' + urlParam, {
      from: yearStart,
      to: yearEnd
    })
  },

  getForecastOfferForYear(year) {
    return axios.post('/v1/statistics/offer/years/offer/forecast', {
      from: year
    })
  },

  getForecastArticlesForYear(id) {
    return axios.post('/v1/statistics/offer/articles/forecast', {
      searchFor: id
    })
  },

  getMinimumYearForOffer() {
    return axios.post('/v1/statistics/offer/minYear')
  }
}
