import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue';
export default {
  components: {
    OpenLinkEyeComponent
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    search: '',
    header: [{
      text: 'Name',
      value: 'name',
      align: 'start',
      sortable: true
    }, {
      text: 'Telefonnummer',
      value: 'tel',
      align: 'start',
      sortable: true
    }, {
      text: 'Kundennummer',
      value: 'kdnr',
      align: 'start',
      sortable: true
    }, {
      text: '',
      value: 'actions',
      align: 'end',
      sortable: false
    }]
  })
};