import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('apexchart', {
    ref: "revenueChart",
    attrs: {
      "type": "bar",
      "height": "500",
      "options": _vm.chartOptions,
      "series": _vm.chartSeries
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };