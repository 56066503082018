import axios from 'axios'

export default {
  // ======================= MISC =======================

  async getMinimumYearForRevenue() {
    let response = ''

    try {
      response = await axios.get('/v1/statistics/revenue/minimum')
    } catch (err) {
      response = err
    }

    return response
  },

  async getFilterOptions(filterType, nonStatisticPath) {
    let response = ''

    try {
      if (nonStatisticPath) {
        response = await axios.get('/v1' + filterType)
      } else {
        if (filterType == '/articles/types') {
          response = await axios.get('/v1' + filterType)
        } else {
          response = await axios.get('/v1/statistics' + filterType)
        }
      }
    } catch (err) {
      response = err
    }

    return response
  },

  getRevenueName(revenueType) {
    let revenueName = ''
    switch (revenueType) {
      case 'net':
        revenueName = 'Umsatz'
        break
      case 'db':
        revenueName = 'Deckungsbeitrag'
        break
      default:
        console.error('Not able to find name for the specified id')
        break
    }
    return revenueName
  },

  getDataForCategory(category) {
    const returnObject = {
      idName: '',
      name: '',
      valueName: ''
    }

    switch (category) {
      case 'shops': {
        returnObject.name = 'Filialen'
        returnObject.idName = 'id'
        returnObject.valueName = 'description'
        break
      }
      case 'addressTypes': {
        returnObject.name = 'Adressarten'
        returnObject.idName = 'id'
        returnObject.valueName = 'name'
        break
      }
      case 'customerGroups': {
        returnObject.name = 'Kundengruppen'
        returnObject.idName = 'id'
        returnObject.valueName = 'name'
        break
      }
      case 'articleTypes': {
        returnObject.name = 'Artikeltypen'
        returnObject.idName = 'tpCode'
        returnObject.valueName = 'tpBezeichung'
        break
      }
      case 'articleCategories': {
        returnObject.name = 'Artikelkategorien'
        returnObject.idName = 'lfdnr'
        returnObject.valueName = 'description'
        break
      }
      case 'articleGroups': {
        returnObject.name = 'Artikelgruppen'
        returnObject.idName = 'lfdnr'
        returnObject.valueName = 'description'
        break
      }
      case 'statisticGroups': {
        returnObject.name = 'Statistikgruppen'
        returnObject.idName = 'code'
        returnObject.valueName = 'bezeichnung'
        break
      }
      case 'statisticCode': {
        returnObject.name = 'Statistikcode'
        returnObject.idName = 'code'
        returnObject.idName = 'code'
        break
      }
      case 'commissionGroups': {
        returnObject.name = 'Provisionsgruppen'
        returnObject.idName = 'id'
        returnObject.idName = 'name'
        break
      }
      case 'revenueGroups': {
        returnObject.name = 'Erlösgruppen'
        returnObject.idName = 'id'
        returnObject.valueName = 'name'
        break
      }
      case 'expenseGroups': {
        returnObject.name = 'Aufwandsgruppen'
        returnObject.idName = 'id'
        returnObject.valueName = 'name'
        break
      }
      case 'fibuAccounts': {
        returnObject.name = 'Fibukonten'
        returnObject.idName = 'id'
        returnObject.valueName = 'name'
        break
      }
    }
    return returnObject
  },

  // ======================= REVENUE - DATA =======================

  async getRevenueForYearSpan(
    yearStart,
    yearEnd,
    untilCurrentMonth,
    includeCurrentMonth,
    filters
  ) {
    let response = ''

    try {
      response = await axios.post('/v1/statistics/revenue/yearspan', {
        startYear: yearStart,
        endYear: yearEnd,
        untilCurrentMonth: untilCurrentMonth,
        includeCurrentMonth: includeCurrentMonth,
        filters: filters
      })
    } catch (err) {
      response = err
    }

    return response
  },

  async getMonthlyRevenueForYear(years, filters) {
    let response = ''

    try {
      response = await axios.post('/v1/statistics/revenue/months', {
        years: years,
        filters: filters
      })
    } catch (err) {
      response = err
    }

    return response
  },

  async getRevenueForCategory(year, filters, selectedCategory) {
    let response = ''

    try {
      response = await axios.post('/v1/statistics/revenue/category', {
        year: year,
        filters: filters,
        selectedCategory: selectedCategory
      })
    } catch (err) {
      response = err
    }

    return response
  },

  // ======================= REVENUE - CHECK =======================

  isRevenueResponseValid(revenues) {
    return !(
      !this.checkAllZeroNetDb(revenues) &
      (Object.values(revenues.data).length === 0)
    )
  },

  checkAllZeroNetDb(response) {
    let flag = false

    if (Object.hasOwnProperty.call(response.data, 'categoryRevenue')) {
      const categoryRevenue = response.data.categoryRevenue

      Object.keys(categoryRevenue).forEach(key => {
        if (categoryRevenue[key].net != 0 || categoryRevenue[key].db != 0) {
          flag = true
        }
      })
    } else {
      Object.keys(response.data).forEach(year => {
        if (Object.hasOwnProperty.call(response.data[year], 'shopsRevenue')) {
          const yearlyShopsRevenue = response.data[year]

          if (yearlyShopsRevenue.net != 0 || yearlyShopsRevenue.db != 0) {
            flag = true
          }
        }

        if (Object.hasOwnProperty.call(response.data[year], 'monthRevenue')) {
          const monthRevenue = response.data[year].monthRevenue

          Object.keys(monthRevenue).forEach(key => {
            if (monthRevenue[key].net != 0 || monthRevenue[key].db != 0) {
              flag = true
            }
          })
        }
      })
    }

    return flag
  }
}
