import axios from 'axios'

export default {
  getTotalCustomerDevelopmentOfYear(
    year,
    locations,
    addressType,
    customerGroup
  ) {
    return axios.post(`/v1/statistics/customer/total/months`, {
      year: year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup
    })
  },

  getCustomerDocuments(
    type,
    art_kategorie,
    lfdnr,
    from_Year,
    art_nr,
    to_Year,
    fibu_kto,
    code,
    art_gruppe,
    art_type,
    art_statcode
  ) {
    return axios.post('/v1/statistics/suppliercustomer/movements/' + type, {
      art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      artNr: art_nr,
      art_gruppe: art_gruppe,
      art_type: art_type,
      art_statcode: art_statcode
    })
  },

  getArticlesbyType(
    type
    /*  art_kategorie,
    lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_gruppe,
    art_type,
    art_statcode*/
  ) {
    return axios.get('/v1/statistics/articles/types/' + type + '/articles', {
      /*art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_gruppe: art_gruppe,
      art_type: art_type,
      art_statcode: art_statcode*/
    })
  },

  getArticlesbyType2(
    //  art_kategorie,
    art_nr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_gruppe,
    art_type,
    art_statcode
  ) {
    return axios.post('/v1/statistics/suppliercustomer/movements/consumer', {
      //  art_kategorie: art_kategorie,
      art_nr: art_nr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_gruppe: art_gruppe,
      art_type: art_type,
      art_statcode: art_statcode
    })
  },

  getArticlesbyType3(
    art_kategorie,
    art_nr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_gruppe,
    art_type,
    art_statcode
  ) {
    return axios.post('/v1/statistics/suppliercustomer/movements/supplier', {
      art_kategorie: art_kategorie,
      art_nr: art_nr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_gruppe: art_gruppe,
      art_type: art_type,
      art_statcode: art_statcode
    })
  },

  getArticlesbyCategorie(
    art_kategorie
    /* lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_gruppe,
    art_type,
    art_statcode*/
  ) {
    return axios.get(
      '/v1/statistics/articles/types/categories/' + art_kategorie + '/article',
      {
        art_kategorie: art_kategorie
        /*lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_gruppe: art_gruppe,
      art_type: art_type,
      art_statcode: art_statcode*/
      }
    )
  },

  getArticlesbyGroup(
    art_gruppe
    /*art_kategorie,
    lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_type,
    art_statcode*/
  ) {
    return axios.get(
      '/v1/statistics/articles/types/groups/' + art_gruppe + '/article',
      {
        art_gruppe: art_gruppe
        /*art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_type: art_type,
      art_statcode: art_statcode*/
      }
    )
  },

  getArticlesbystatGroup(
    art_statgroup
    //art_gruppe
    /*art_kategorie,
    lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_type,
    art_statcode*/
  ) {
    return axios.get(
      '/v1/statistics/articles/types/statGroup/' + art_statgroup + '/article',
      {
        art_statgroup: art_statgroup
        //art_gruppe: art_gruppe,
        /*art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_type: art_type,
      art_statcode: art_statcode*/
      }
    )
  },

  getArticlesbystatCode(
    art_statcode
    //art_gruppe
    /*art_kategorie,
    lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_type,*/
  ) {
    return axios.get(
      '/v1/statistics/articles/types/statcode/' + art_statcode + '/articles',
      {
        art_statcode: art_statcode
        /*art_gruppe: art_gruppe,
      art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_type: art_type,*/
      }
    )
  },

  getArticlesbyerloesgruppe(
    fibu_kto
    //art_statcode
    //art_gruppe
    /*art_kategorie,
    lfdnr,
    from_Year,
    to_Year,
    fibu_kto,
    code,
    art_type,*/
  ) {
    return axios.get(
      '/v1/statistics/articles/types/erloesGroup/' + fibu_kto + '/articles',
      {
        fibu_kto: fibu_kto
        //art_statcode: art_statcode
        /*art_gruppe: art_gruppe,
      art_kategorie: art_kategorie,
      lfdnr: lfdnr,
      from_Year: from_Year,
      to_Year: to_Year,
      fibu_kto: fibu_kto,
      code: code,
      art_type: art_type,*/
      }
    )
  },

  getDBPosNetfromCustomer(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    db,
    pos_netto
  ) {
    return axios.post('/v1/statistics/customer/dbum/years', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      db: db,
      pos_netto: pos_netto
    })
  },

  getDBPosNetfromCustomer2(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup
  ) {
    return axios.post('/v1/statistics/customer/document', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup
    })
  },

  getDBPosNetfromSupplier(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    db,
    pos_netto
  ) {
    return axios.post('/v1/statistics/supplier/dbum/years', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      db: db,
      pos_netto: pos_netto
    })
  },

  getStrongestCustomers(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    size
  ) {
    return axios.post('/v1/statistics/customer/top/customer', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      size: size
    })
  },

  getStrongestCustomers2(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    size
  ) {
    return axios.post('/v1/statistics/customer/top/customer/inverted', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      size: size
    })
  },

  getStrongestSuppliers(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    size
  ) {
    return axios.post('/v1/statistics/supplier/top/supplier', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      size: size
    })
  },

  getStrongestSuppliers2(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup,
    size
  ) {
    return axios.post('/v1/statistics/supplier/top/supplier/inverted', {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      size: size
    })
  },

  getAllPersonList(type) {
    return axios.get('/v1/statistics/suppliercustomer/personList/' + type, {})
  },
  getCustomerDetailsType(from_Year, to_Year, lfdnr, type, ftype) {
    return axios.post(
      '/v1/statistics/suppliercustomer/annualDevelopment/' + type + '/' + ftype,
      {
        from_Year: from_Year,
        to_Year: to_Year,
        lfdnr: lfdnr
      }
    )
  },
  getCustomerDetailsFType(customer) {
    return axios.post(
      '/v1/statistics/suppliercustomer/annualDevelopment/ftype',
      {
        customer: customer
      }
    )
  },

  getTotalCustomerDevelopmentOfFiveYear(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup
  ) {
    return axios.post(`/v1/statistics/customer/total/years`, {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup
    })
  },
  getTotalSupplierDevelopmentOfFiveYear(
    from_Year,
    to_Year,
    locations,
    addressType,
    customerGroup
  ) {
    return axios.post(`/v1/statistics/supplier/total/years`, {
      from_Year: from_Year,
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup
    })
  },
  getDetaillisteOfAllStores() {
    return axios.get(`/v1/company/locations`)
  },
  getStoreDeveolpmentOfFiveYears(year, postcode) {
    return axios.get(`/v1/statistics/customer/locations/years`, {
      params: { year: year, location: postcode }
    })
  },
  getStoreDeveolpmentOfOneYear(year, locationId) {
    return axios.get(`/v1/statistics/customer/locations/months`, {
      params: { year: year, location: locationId }
    })
  },
  getListOfAllCustomerGroups() {
    return axios.get(`/v1/statistics/customer/groups`)
  },
  getCustomerGroupsDevelopmentOfFiveYears(startyear, group) {
    return axios.get(`/v1/statistics/customer/groups/years`, {
      params: { year: startyear, customerGroup: group }
    })
  },
  getCustomerGroupsDevelopmentOfOneYear(year, group) {
    return axios.get(`/v1/statistics/customer/groups/months`, {
      params: { year: year, customerGroup: group }
    })
  },
  getListOfAllAddressTypes() {
    return axios.get(`/v1/statistics/customer/address/types`)
  },
  getAddressTypesDevelopmentOfFiveYears(startyear, typ) {
    return axios.get(`/v1/statistics/customer/address/types/years`, {
      params: { year: startyear, addressType: typ }
    })
  },
  getAddressTypesDevelopmentOfOneYear(year, typ) {
    return axios.get(`/v1/statistics/customer/address/types/months`, {
      params: { year: year, addressType: typ }
    })
  },
  getListOfNewCustomers(
    to_Year,
    locations,
    addressType,
    customerGroup,
    from_Year,
    month
  ) {
    return axios.post(`/v1/statistics/customer/new/months`, {
      to_Year: to_Year,
      locations: locations,
      addressType: addressType,
      customerGroup: customerGroup,
      from_Year: from_Year,
      month: month
    })
  },

  getListOfNewCustomersForLocations(year, month, locations) {
    let locationQueryString = ''

    for (let i = 0; i < locations.length; i++) {
      locationQueryString += locations[i]

      if (i < locations.length - 1) {
        locationQueryString += ','
      }
    }

    return axios.get(
      `/v1/statistics/customer/locations/${year}/${month}?locations=${locationQueryString}`
    )
  },

  getOffers(
    from_Year,
    to_Year,
    from_NettoValue,
    to_NettoValue,
    from_Amount,
    to_Amount,
    articles,
    art,
    statistic_gruppe,
    statistic_code,
    prov_gruppe,
    erloes_gruppe,
    objekt_typ,
    objekt_typ_gruppe
  ) {
    return axios.post('/v1/statistics/offer/information', {
      from_Year: from_Year,
      to_Year: to_Year,
      from_NettoValue: from_NettoValue,
      to_NettoValue: to_NettoValue,
      from_Amount: from_Amount,
      to_Amount: to_Amount,
      articles: articles,
      art: art,
      statistic_gruppe: statistic_gruppe.lfdnr,
      statistic_code: statistic_code.code,
      prov_gruppe: prov_gruppe.code,
      erloes_gruppe: erloes_gruppe.id,
      objekt_typ: objekt_typ.lfdnr,
      objekt_typ_gruppe: objekt_typ_gruppe.lfdnr
    })
  },

  getDocumentsTypes(
    from_Year,
    to_Year,
    from_NettoValue,
    to_NettoValue,
    from_Amount,
    to_Amount,
    form_gruppe,
    articles,
    statistic_gruppe,
    statistic_code,
    prov_gruppe,
    erloes_gruppe,
    objekt_typ,
    objekt_typ_gruppe
  ) {
    return axios.post('/v1/statistics/documentStock/years', {
      from_Year: from_Year,
      to_Year: to_Year,
      from_NettoValue: from_NettoValue,
      to_NettoValue: to_NettoValue,
      from_Amount: from_Amount,
      to_Amount: to_Amount,
      form_gruppe: form_gruppe,
      articles: articles,
      statistic_gruppe: statistic_gruppe.lfdnr,
      statistic_code: statistic_code.code,
      prov_gruppe: prov_gruppe.code,
      erloes_gruppe: erloes_gruppe.id,
      objekt_typ: objekt_typ.lfdnr,
      objekt_typ_gruppe: objekt_typ_gruppe.lfdnr
    })
  },
  getDocumentsGroups() {
    return axios.get('/v1/statistics/documentStock/formulargruppen', {})
  }
}
