import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VTextField, {
    staticClass: "my-5",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.header,
      "items": _vm.items,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "kdnr"
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c('OpenLinkEyeComponent', {
          attrs: {
            "destination": {
              name: 'crmAddress',
              params: {
                lfdnr: item.kdnr
              }
            },
            "newTab": true
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };