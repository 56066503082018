import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [[_c(VCardTitle, [_vm._v(" Filialen auswählen ")]), _c(VCardText, [_c(VTextField, {
    staticClass: "shrink mb-3",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.shops,
      "search": _vm.search,
      "items-per-page": 10,
      "item-key": "id",
      "show-select": "",
      "sort-by": "name",
      "dense": ""
    },
    on: {
      "input": _vm.updateChart
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], _vm.selected.length != 0 ? _c('div', [_c('div', {
    staticClass: "header pl-8"
  }, [_c('h2', [_vm._v(" Gesamtentwicklung der Kunden "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.highestYearInFiveShownYears - 4) + "-" + _vm._s(this.highestYearInFiveShownYears))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('div', {
    attrs: {
      "id": "yearChart"
    }
  }, [_c('apexchart', {
    ref: "totalChart",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.yearChartOptions,
      "series": _vm.total
    }
  })], 1)]) : _vm._e(), _vm.currentYear != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Monatsansicht "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.currentYear))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('div', {
    attrs: {
      "id": "monthChart"
    }
  }, [_c('apexchart', {
    ref: "monthChart",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.monthChartOptions,
      "series": _vm.year
    }
  })], 1), _c('customerDatatable', {
    attrs: {
      "items": _vm.newCustomersItems
    }
  })], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };