import FormatService from '@/services/formatters/currencyFormatService.js';
const REVENUE_TYPES = ['net', 'db', 'netTotal', 'dbTotal'];
export default {
  props: {
    highestYearInFiveShownYears: {
      type: Number,
      required: true
    },
    dbYear: {
      type: Array,
      required: true
    },
    revenueYear: {
      type: Array,
      required: true
    },
    dbSum: {
      type: Array,
      required: true
    },
    posNettoSum: {
      type: Array,
      required: true
    },
    headLineText: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      total: [{
        name: 'Deckungsbeitrag',
        type: 'line'
      }, {
        name: 'Umsatz',
        type: 'line'
      }, {
        name: 'Deckungsbeitrag gesamt',
        type: 'bar'
      }, {
        name: 'Umsatz gesamt',
        type: 'bar'
      }],
      totalChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showMonth', this.highestYearInFiveShownYears - 4 + config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
        },
        yaxis: [{
          decimalsInFloat: 0,
          lables: {
            formatter: value => FormatService.formatEuro(value)
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      }
    };
  },
  methods: {
    updateChart() {
      this.$refs.totalChart.updateSeries([{
        name: 'Umsatz',
        data: this.revenueYear,
        type: 'line'
      }, {
        name: 'Deckungsbeitrag',
        data: this.dbYear,
        type: 'line'
      }, {
        name: 'Umsatz gesamt',
        data: this.posNettoSum,
        type: 'bar'
      }, {
        name: 'Deckungsbeitrag gesamt',
        data: this.dbSum,
        type: 'bar'
      }]), this.$refs.totalChart.updateOptions({
        xaxis: {
          categories: [this.highestYearInFiveShownYears - 4, this.highestYearInFiveShownYears - 3, this.highestYearInFiveShownYears - 2, this.highestYearInFiveShownYears - 1, this.highestYearInFiveShownYears]
        }
      });
    }
  },
  watch: {
    dbYear() {
      this.updateChart();
    }
  }
};