var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('RevenueChart', {
    ref: "monthChart",
    attrs: {
      "chartType": "month",
      "chartSeries": _vm.chartSeries,
      "chartYaxisData": _vm.getMonthLabels,
      "totalSeries": _vm.totalSeries,
      "legendItems": [],
      "titleText": `Monatsvergleich - ${_vm.getTitle}`,
      "xaxisText": "Monat",
      "yaxisText": _vm.getTitle,
      "stackedChart": false,
      "showBothRevenueTypes": _vm.showBothRevenueTypes
    },
    on: {
      "updateCharts": function ($event) {
        return _vm.$emit('updateCharts');
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };